// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Knowledge from 'components/Contentful/Knowledge'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Danke"
        meta={[
          { name: 'description', content: '' },
          { name: 'keywords', content: '' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='1hGU9yRiC6QMEuMc8Q0OaQ-4Zaw6UhvKE0YCske0QKCSi'
          id='danke'
        >
          <Knowledge
            {...{
    "type": "knowledge",
    "id": "4Zaw6UhvKE0YCske0QKCSi",
    "updatedAt": "2018-04-04T08:41:23.627Z",
    "title": "Danke! ",
    "description": "<p>Vielen Dank für ihre Anfrage! Unsere Experten kümmern sich um ihr Anliegen und werden mit Ihnen Kontakt aufnehmen.</p>\n",
    "link": {
        "type": "link",
        "id": "6cbzcLrAhUSwSOUEA22eqe",
        "updatedAt": "2018-10-15T14:30:12.902Z",
        "label": "zurück zur Webseite",
        "url": "https://www.heizungsmacher.ch"
    },
    "anchorName": "danke",
    "details": []
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
